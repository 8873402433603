import { StaticQuery, graphql, Link } from "gatsby"
import React, { useState } from "react"
import "ionicons"
import styles from "../components/header.module.scss"

const HeaderContent = ({ hero, data }) => {
  const {
    pageData: {
      header
    }
  } = data

  const [navToggle, setNavToggle] = useState(false)
  const sortedHeader = header.sort((a, b) => a.order - b.order)

  return (
  <header id="top" className={`navbar is-dark ${styles.navbar}`}>
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          The church in Cypress
        </Link>
        <span 
          tabIndex="0"
          role="link"
          onKeyPress={() => {setNavToggle(!navToggle)}} 
          className={`navbar-burger burger ${navToggle ? 'is-active': ''}`} 
          onClick={() => {setNavToggle(!navToggle)}} 
          data-target="navbarMenuHeroC">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div id="navbarMenuHeroC" className={`navbar-menu ${navToggle ? 'is-active': ''} ${styles.navbarMenu}`}>
        <div className="navbar-end">
          {sortedHeader.map(link => {
            if (link.button === true) {
              return (
                <span key={link.href} className={`navbar-item ${styles.navbarItem}`}>
                  <Link to={link.href} className="button is-info">
                    {/* <span className="icon">
                      <ion-icon name="heart"></ion-icon>
                    </span> */}
                    <span>{link.text}</span>
                  </Link>
                </span>
              )
            } else {
              return (
                <Link key={link.href} to={link.href} className="navbar-item">
                  {link.text}
                </Link>
              )
            }
          })}
          
        </div>
      </div>
    </div>  
  </header>
)}

export default ({ hero }) => (
  <StaticQuery 
    query={graphql`
      query HeaderQuery {
        pageData {
          header {
            brand
            button
            external
            href
            order
            parent
            text
          }
        }
      }
    `}
    render={data => {
      if (hero !== undefined) {
        if (hero === "full") {
          return (
            <div className="hero-head">
              <HeaderContent hero={hero} data={data}/>
            </div>
          )
        }
      } else {
        return (
         <HeaderContent data={data} /> 
        )
      }
    }}
  />
)
