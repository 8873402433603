/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Footer = () => (
  <footer style={{marginTop: `8em`}} className="footer">
    <div className="content has-text-centered">
      <p>
        Copyright &copy; 2023 The Church in Cypress. All rights reserved.
      </p>
    </div>
  </footer>
)

const Layout = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const hero = (props.hero ? props.hero : undefined)
  
  if (hero === undefined) {
    return (
      <>
        <Header 
          siteTitle={data.site.siteMetadata.title} 
        />
        <div
          style={{
            margin: `0 auto`,
            paddingTop: 0,
          }}
        >
          <section className="heading">
            <div className="container">
              <h2 className="title">{props.title}</h2>
            </div>
          </section>
          <main>{children}</main>
          <Footer />
          {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div
          style={{
            margin: `0 auto`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <Footer />
          {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
        </div>
      </>
    )
  }

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
